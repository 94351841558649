import React, { useEffect, useState, useRef } from "react";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import CONFIG from "./config.json";
import { ethers } from "ethers";

import ABI from "./ABI.json";

const truncate = (input, len) =>
	input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
	padding: 10px;
	border-radius: 50px;
	border: none;
	background-color: var(--secondary);
	padding: 10px;
	font-weight: bold;
	color: var(--secondary-text);
	width: 100px;
	cursor: pointer;
	box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
	-webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
	-moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
	:active {
		box-shadow: none;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
	}
`;

export const StyledRoundButton = styled.button`
	padding: 10px;
	border-radius: 100%;
	border: none;
	background-color: var(--primary);
	padding: 10px;
	font-weight: bold;
	font-size: 15px;
	color: var(--primary-text);
	width: 30px;
	height: 30px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
	-webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
	-moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
	:active {
		box-shadow: none;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
	}
`;

export const ResponsiveWrapper = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: stretched;
	align-items: stretched;
	width: 100%;
	@media (min-width: 767px) {
		flex-direction: row;
	}
`;

export const StyledLogo = styled.img`
	width: 200px;
	@media (min-width: 767px) {
		width: 300px;
	}
	transition: width 0.5s;
	transition: height 0.5s;
`;

export const StyledImg = styled.img`
	box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
	border: 4px dashed var(--secondary);
	background-color: var(--accent);
	border-radius: 100%;
	width: 200px;
	@media (min-width: 900px) {
		width: 250px;
	}
	@media (min-width: 1000px) {
		width: 300px;
	}
	transition: width 0.5s;
`;

export const StyledLink = styled.a`
	color: var(--secondary);
	text-decoration: none;
`;

const contractAddress = "0x9342dE2f03ff766bd3a2c0BeFa089870beE67D0A";

function App() {
	const [value, setValue] = useState(1);
	const [currentAccount, setCurrentAccount] = useState("");
	const [totalMinted, setTotalMinted] = useState(0);
	const [mintPrice, setMintPrice] = useState();
	const [minting, setMinting] = useState(false);
	const [modalIsOpen, setIsOpen] = useState(false);

	useEffect(() => {
		checkIfWalletIsConnected();
		getPrice();
		getCount();

		if (window.ethereum) {
			window.ethereum.on("accountsChanged", checkIfWalletIsConnected);
			window.ethereum.on("disconnect", checkIfWalletIsConnected);
		}
	}, []);

	// Get Supply
	const getCount = async () => {
		const provider = new ethers.providers.Web3Provider(window.ethereum);
		const contract = new ethers.Contract(contractAddress, ABI, provider);

		const count = await contract.totalSupply();
		setTotalMinted(parseInt(count));
	};

	// Get Mint Price
	const getPrice = async () => {
		const provider = new ethers.providers.Web3Provider(window.ethereum);
		const contract = new ethers.Contract(contractAddress, ABI, provider);

		const price = await contract.cost();
		setMintPrice(price / 10 ** 18);
	};

	const checkIfWalletIsConnected = async () => {
		const { ethereum } = window;

		if (!ethereum) {
			toast("Make sure you have metamask!");
			return;
		}

		const networkId = await ethereum.request({
			method: "net_version",
		});

		if (networkId === 137) {
			toast("Make sure you are in polygon network!");
			return;
		}

		const accounts = await ethereum.request({ method: "eth_accounts" });

		if (accounts.length !== 0) {
			setIsOpen(false);
			const account = accounts[0];
			setCurrentAccount(account);
		} else {
			setCurrentAccount("");
			toast("No authorized account found");
		}
	};

	// Connect Wallet
	const connectWallet = async () => {
		const { ethereum } = window;

		const networkId = await ethereum.request({
			method: "net_version",
		});

		if (networkId === 137) {
			toast("Make sure you are in polygon network!");
			return;
		}

		const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
		await provider.send("eth_requestAccounts", []);
		const signer = provider.getSigner();
		await signer.getAddress();
	};

	// Mint an NFT
	const mintToken = async () => {
		const provider = new ethers.providers.Web3Provider(window.ethereum);
		const signer = provider.getSigner();
		const connectedContract = new ethers.Contract(contractAddress, ABI, signer);
		const cost = await connectedContract.cost();

		try {
			const result = await connectedContract.mint(value, {
				value: cost.mul(value),
			});
			setMinting(true);
			await result.wait();
			setMinting(false);
			toast.success("Mint Successful.", { position: "bottom-right" });
			getCount();
		} catch (err) {
			console.log(err);
			toast.error(err.message, { position: "bottom-right" });
		}
	};

	// Handle NFTs Count
	const handleCount = (type = "inc") => {
		if (type === "inc") {
			if (value < 4) {
				setValue(value + 1);
			}
		} else {
			if (value > 1) {
				setValue(value - 1);
			}
		}
	};

	return (
		<s.Screen>
			<ToastContainer />
			<s.Container
				flex={1}
				ai={"center"}
				style={{ padding: 24, backgroundColor: "var(--primary)" }}
				image="/config/images/bg.png"
			>
				<StyledLogo alt={"logo"} src={"/config/images/logo.png"} />
				<s.SpacerSmall />
				<ResponsiveWrapper flex={1} style={{ padding: 24 }} test>
					<s.Container flex={1} jc={"center"} ai={"center"}>
						<StyledImg alt={"example"} src={"/config/images/example.gif"} />
					</s.Container>
					<s.SpacerLarge />
					<s.Container
						flex={2}
						jc={"center"}
						ai={"center"}
						style={{
							backgroundColor: "var(--accent)",
							padding: 24,
							borderRadius: 24,
							border: "4px dashed var(--secondary)",
							boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
						}}
					>
						<s.TextTitle
							style={{
								textAlign: "center",
								fontSize: 50,
								fontWeight: "bold",
								color: "var(--accent-text)",
							}}
						>
							{totalMinted} / 1000
						</s.TextTitle>
						<s.TextDescription
							style={{
								textAlign: "center",
								color: "var(--primary-text)",
							}}
						>
							<StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
								{truncate(CONFIG.CONTRACT_ADDRESS, 15)}
							</StyledLink>
						</s.TextDescription>
						<s.SpacerSmall />
						{totalMinted >= 1000 ? (
							<>
								<s.TextTitle
									style={{ textAlign: "center", color: "var(--accent-text)" }}
								>
									The sale has ended.
								</s.TextTitle>
								<s.TextDescription
									style={{ textAlign: "center", color: "var(--accent-text)" }}
								>
									You can still find {CONFIG.NFT_NAME} on
								</s.TextDescription>
								<s.SpacerSmall />
								<StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
									{CONFIG.MARKETPLACE}
								</StyledLink>
							</>
						) : (
							<>
								<s.TextTitle
									style={{ textAlign: "center", color: "var(--accent-text)" }}
								>
									1 {CONFIG.SYMBOL} costs {CONFIG.DISPLAY_COST} MATIC.
								</s.TextTitle>
								<s.SpacerXSmall />
								<s.TextDescription
									style={{ textAlign: "center", color: "var(--accent-text)" }}
								>
									Excluding gas fees.
								</s.TextDescription>
								<s.SpacerSmall />
								{!currentAccount ? (
									<s.Container ai={"center"} jc={"center"}>
										<s.TextDescription
											style={{
												textAlign: "center",
												color: "var(--accent-text)",
											}}
										>
											Connect to the {CONFIG.NETWORK.NAME} network
										</s.TextDescription>
										<s.SpacerSmall />
										<StyledButton
											onClick={(e) => {
												e.preventDefault();
												connectWallet();
											}}
										>
											CONNECT
										</StyledButton>
										{/* {blockchain.errorMsg !== "" ? (
											<>
												<s.SpacerSmall />
												<s.TextDescription
													style={{
														textAlign: "center",
														color: "var(--accent-text)",
													}}
												>
													{blockchain.errorMsg}
												</s.TextDescription>
											</>
										) : null} */}
									</s.Container>
								) : (
									<>
										<s.TextDescription
											style={{
												textAlign: "center",
												color: "var(--accent-text)",
											}}
										>
											{/* {feedback} */}
										</s.TextDescription>
										<s.SpacerMedium />
										<s.Container ai={"center"} jc={"center"} fd={"row"}>
											<StyledRoundButton
												style={{ lineHeight: 0.4 }}
												disabled={minting ? 1 : 0}
												onClick={() => handleCount("dec")}
											>
												-
											</StyledRoundButton>
											<s.SpacerMedium />
											<s.TextDescription
												style={{
													textAlign: "center",
													color: "var(--accent-text)",
												}}
											>
												{value}
											</s.TextDescription>
											<s.SpacerMedium />
											<StyledRoundButton
												disabled={minting ? 1 : 0}
												onClick={() => handleCount("inc")}
											>
												+
											</StyledRoundButton>
										</s.Container>
										<s.SpacerSmall />
										<s.Container ai={"center"} jc={"center"} fd={"row"}>
											<StyledButton
												disabled={minting ? 1 : 0}
												onClick={(e) => {
													e.preventDefault();
													mintToken(value);
												}}
											>
												{minting ? "BUSY" : "BUY"}
											</StyledButton>
										</s.Container>
									</>
								)}
							</>
						)}
						<s.SpacerMedium />
					</s.Container>
					<s.SpacerLarge />
					<s.Container flex={1} jc={"center"} ai={"center"}>
						<StyledImg
							alt={"example"}
							src={"/config/images/example.gif"}
							style={{ transform: "scaleX(-1)" }}
						/>
					</s.Container>
				</ResponsiveWrapper>
				<s.SpacerMedium />
				<s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>
					<s.TextDescription
						style={{
							textAlign: "center",
							color: "var(--primary-text)",
						}}
					>
						Please make sure you are connected to the right network (
						{CONFIG.NETWORK.NAME} Mainnet) and the correct address. Please note:
						Once you make the purchase, you cannot undo this action.
					</s.TextDescription>
					<s.SpacerSmall />
					<s.TextDescription
						style={{
							textAlign: "center",
							color: "var(--primary-text)",
						}}
					>
						We have set the gas limit to {CONFIG.GAS_LIMIT} for the contract to
						successfully mint your NFT. We recommend that you don't lower the
						gas limit.
					</s.TextDescription>
				</s.Container>
			</s.Container>
		</s.Screen>
	);
}

export default App;
